/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnCover, ColumnDiv, SeparateLine, SeparateLineWrap, Title, Text, PriceWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"} description={"Plnění lahví, půjčovna potápěčské výstroje, bazar"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column style={{"paddingTop":57,"paddingBottom":38.8125}} anim={null} name={"zacatek"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":788}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=1400x_.jpg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape5 ff--3" href={"#plnirna"} content={"Plnírna lahví"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/852dfd96e6694123814cd8a0e645c356_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/852dfd96e6694123814cd8a0e645c356_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/852dfd96e6694123814cd8a0e645c356_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/852dfd96e6694123814cd8a0e645c356_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/852dfd96e6694123814cd8a0e645c356_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/852dfd96e6694123814cd8a0e645c356_s=2000x_.jpg 2000w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape5 ff--3" href={"#pujcovna"} content={"Půjčovna výstroje"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/f3b51a0db5444cf89b4f30ef48c82c67_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/f3b51a0db5444cf89b4f30ef48c82c67_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/f3b51a0db5444cf89b4f30ef48c82c67_s=660x_.jpg 660w"} position={{"x":"-78.15384615384603%","y":"-20.615384615384606%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape5 ff--3" href={"#servis"} content={"Servis"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"bttiuksn12o"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"marginTop":0,"paddingTop":7,"marginBottom":24,"paddingBottom":0.958343505859375}} name={"plnirna"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" content={"Plnírna potápěčských lahví<br>"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--20" style={{"marginTop":30}} content={"<span style=\"color: rgb(255, 0, 0); text-decoration: underline; font-weight: bold;\">PLNÍME POUZE PRO POTÁPĚČSKÉ ÚČELY DO LAHVÍ S PLATNÝM ATESTEM (v ČR - 5 let)!!!</span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3" content={"Lahve musí mít standardní závit bez redukcí.<br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3" style={{"marginTop":23.050018310546875,"paddingTop":0}} content={"Členové klubu Orca mají v pražské plnírně foukání za 50%.<br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--30" content={"Ceníky:"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":3,"paddingBottom":5.10833740234375}} name={"qp9pn477mk"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex el--2" style={{"maxWidth":476,"marginBottom":0,"paddingBottom":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/dec6335a00d744ffb9ffbaca1769fb1d_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/dec6335a00d744ffb9ffbaca1769fb1d_s=350x_.jpg 350w"} lightbox={true} position={{"x":"0%","y":"-8.615384615384615%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ba4f86e10b3c41899e863f2dbb6f5059_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/ba4f86e10b3c41899e863f2dbb6f5059_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ba4f86e10b3c41899e863f2dbb6f5059_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ba4f86e10b3c41899e863f2dbb6f5059_s=860x_.jpg 860w"} position={{"x":"-45.53846153846153%","y":"-4.923076923076924%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":6,"paddingBottom":13.208343505859377}} name={"huk905ddiii"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":550}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/ffbffbc79faf4e1fa3ea13a513d9e596_s=1400x_.jpg 1400w"} lightbox={true} position={{"x":"0%","y":"-8.333333333333332%"}} description={"Silent EVO 16"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/2eb234d975b44c5f8a6be37fd5193d81_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/2eb234d975b44c5f8a6be37fd5193d81_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2eb234d975b44c5f8a6be37fd5193d81_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2eb234d975b44c5f8a6be37fd5193d81_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/2eb234d975b44c5f8a6be37fd5193d81_s=1400x_.jpg 1400w"} lightbox={true} position={{"x":"0%","y":"-78.24074074074065%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/d23d64abee75422ca9fbe2ba2a2ec3cb_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/d23d64abee75422ca9fbe2ba2a2ec3cb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d23d64abee75422ca9fbe2ba2a2ec3cb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d23d64abee75422ca9fbe2ba2a2ec3cb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d23d64abee75422ca9fbe2ba2a2ec3cb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/d23d64abee75422ca9fbe2ba2a2ec3cb_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-31.94444444444445%","y":"-1.8518518518518519%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0}} name={"op158ycjng"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3" style={{"maxWidth":654}} content={"<a href=\"#zacatek\">zpět</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"95a7na6oiu"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"marginTop":0,"paddingTop":7,"marginBottom":0,"paddingBottom":0}} name={"pujcovna"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" content={"Půjčovna výstroje<br>"}>
              </Title>

              <Text className="text-box text-box--center ff--3" style={{"marginTop":33.125,"paddingTop":0}} content={"Můžeme Vám zapůjčit jakoukoli součást potápěčské výstroje. Pokud si půjčujete věci na náš bazén, cena je 100 Kč za každou zapůjčenou věc (ABC zdarma). Členové klubu Orca mají 50% slevu na půjčovném a neplatí kauci.<br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--30" content={"Ceník:"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}} name={"ji5hnwlyft"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":272}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":256}} src={"https://cdn.swbpg.com/t/13078/80fbf7c55edf45d58cffa7fc3b58dc81_s=660x_.png"} svg={false} ratio={"1:1"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/80fbf7c55edf45d58cffa7fc3b58dc81_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/80fbf7c55edf45d58cffa7fc3b58dc81_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/80fbf7c55edf45d58cffa7fc3b58dc81_s=860x_.png 860w"} lightbox={true} position={{"x":"-15.416666666666664%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":14,"marginBottom":0,"paddingBottom":44.45831298828125}} name={"o2l9qesmsvo"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":742,"marginTop":0,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/57a7a8e5f11d4173b626102f1dfa3f64_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/57a7a8e5f11d4173b626102f1dfa3f64_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/57a7a8e5f11d4173b626102f1dfa3f64_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/57a7a8e5f11d4173b626102f1dfa3f64_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/57a7a8e5f11d4173b626102f1dfa3f64_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/57a7a8e5f11d4173b626102f1dfa3f64_s=2000x_.jpg 2000w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/2aa6d8cf015c4b72bfc783546a82da5f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/2aa6d8cf015c4b72bfc783546a82da5f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2aa6d8cf015c4b72bfc783546a82da5f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2aa6d8cf015c4b72bfc783546a82da5f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/2aa6d8cf015c4b72bfc783546a82da5f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/2aa6d8cf015c4b72bfc783546a82da5f_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-44.206008583690995%","y":"-4.291845493562231%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/0d2e731e5672487eb76ccbf54f98d88e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/0d2e731e5672487eb76ccbf54f98d88e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/0d2e731e5672487eb76ccbf54f98d88e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/0d2e731e5672487eb76ccbf54f98d88e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/0d2e731e5672487eb76ccbf54f98d88e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/0d2e731e5672487eb76ccbf54f98d88e_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-44.206008583690995%","y":"-4.291845493562231%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/935b0113ce124e2a97cdbd77d2b68939_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/935b0113ce124e2a97cdbd77d2b68939_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/935b0113ce124e2a97cdbd77d2b68939_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/935b0113ce124e2a97cdbd77d2b68939_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/935b0113ce124e2a97cdbd77d2b68939_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/935b0113ce124e2a97cdbd77d2b68939_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-82.83261802575103%","y":"-13.304721030042925%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/7a56530bc5bf47c88a8e21bb2ac2e9f5_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/7a56530bc5bf47c88a8e21bb2ac2e9f5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7a56530bc5bf47c88a8e21bb2ac2e9f5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7a56530bc5bf47c88a8e21bb2ac2e9f5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/7a56530bc5bf47c88a8e21bb2ac2e9f5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/7a56530bc5bf47c88a8e21bb2ac2e9f5_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-12.017167381974248%","y":"-20.145922746781117%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-44.206008583690995%","y":"-4.291845493562231%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0}} name={"op158ycjng"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3" style={{"maxWidth":654}} content={"<a href=\"#zacatek\">zpět</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"8ynluo9z30h"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"marginTop":0,"paddingTop":7,"marginBottom":24,"paddingBottom":0.958343505859375}} name={"servis"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" content={"Servis potápěčské techniky<br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"marginTop":21.416671752929688}} content={"Uvedené ceny servisu potápěčské techniky se mohou měnit v závislosti na cenách náhradních dílů."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":2,"paddingBottom":0}} name={"te9kbv21li"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":562,"paddingTop":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/ccb2d5625f324c908beed53463ca27e1_s=2000x_.jpg 2000w"} position={{"x":"-53.475935828876985%","y":"0%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 ff--3 fs--14" href={"#automatiky"} content={"Automatika"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/o/13078/e5d998e48eae49e0aefb0dd0d7f66fca.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 ff--3 fs--14" href={"#kridla"} content={"Křídlo"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/bd5a7cc47011439f9c03e05d99d4105b_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/bd5a7cc47011439f9c03e05d99d4105b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/bd5a7cc47011439f9c03e05d99d4105b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/bd5a7cc47011439f9c03e05d99d4105b_s=860x_.jpg 860w"} position={{"x":"-19.86096256684492%","y":"-18.181818181818183%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 ff--3 fs--14" href={"#lahve"} content={"Lahev a ventil"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":12.4375}} name={"hu0m8w2mgdj"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":370}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/1530b457b96d4c098403ad67934a8f49_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/1530b457b96d4c098403ad67934a8f49_s=350x_.jpg 350w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 ff--3 fs--14" href={"#pociatce"} content={"Počítač"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/1cda19e2ac0b412184ce0ffb3833f7d2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/1cda19e2ac0b412184ce0ffb3833f7d2_s=350x_.jpg 350w"} position={{"x":"0%","y":"0%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr5 btn-box--pbtn2 btn-box--shape4 ff--3 fs--14" href={"#suchace"} content={"Suchý oblek"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0}} name={"op158ycjng"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3" style={{"maxWidth":654}} content={"<a href=\"#zacatek\">zpět</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"r26fjbkvmp"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":12.791671752929688}} name={"automatiky"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":866,"paddingTop":23}} columns={"1"}>
            
            <ColumnWrapper style={{"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--26" content={"<font color=\"#c81414\">Regulátory</font>"}>
              </Title>

              <Text className="text-box" style={{"marginTop":13.966667175292969}} content={"<font color=\"rgba(0,0,0,1)\">Jsme certifikovaným servisním střediskem regulátorů značky APEKS, TECLINE, ATOMIC a POSEIDON. Ostatní značky dle dohody na základě vizuální prohlídky.</font>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/13078/0ca6fc3a0ff74e2e87e895292e5710a1_s=350x_.jpg"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/0ca6fc3a0ff74e2e87e895292e5710a1_s=350x_.jpg 350w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":8,"paddingBottom":2.7416534423828125}} name={"s6xzgrix9d"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654}} content={"Ceník servisu:"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"paddingBottom":33.875}} name={"19nbcpjdql3"}>
          
          <ColumnWrap className="column__flex el--2" style={{"maxWidth":1109}} columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper style={{"maxWidth":441}}>
                  
                  <Text className="text-box ff--3" style={{"maxWidth":444}} content={"<font color=\"rgba(0,0,0,1)\">Kompletace a tlaková zkouška nového setu regulátorů</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right" style={{"maxWidth":239}}>
                  
                  <Text className="text-box ff--3" content={"250,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\"><b>Pravidelná roční kontrola regulátorů<br>- měření a případné nastavení středotlaku<br>- čištění středotlakých a vysokotlakých výstupů<br>- prohlídka vstupního sintrového filtru<br>- celková vizuální kontrola</b></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"450,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Servis prvního stupně<br>- seřízení (kontrola filtru, změření a seřízení středotlaku)<br>- kompletní servis (rozebrání na prvočinitele, čištění ultrazvukem,      450 Kč \n  seřízení středotlaku, výměna vadných dílů\n  (cena nezahrnuje cenu náhradních dílů)<br>- oxy clean</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<br>150,-<br><br>450,-<br><br><br>+250,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Servis druhého stupně<br>- seřízení (kontrola a seřízení nádechového odporu)<br>- kompletní servis (rozebrání na prvočinitele, čištění ultrazvukem,  výměna vadných dílů (cena nezahrnuje cenu náhradních dílů)\n<br>- oxy clean </font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"<br>250,-<br><br>400,-<br><br><br><br>+250,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Servis celého setu tj. 1x první stupeň + 2x druhý stupeň</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--3" content={"1220,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":29,"marginBottom":0,"paddingBottom":12.791671752929688}} name={"kridla"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":866,"paddingTop":23}} columns={"1"}>
            
            <ColumnWrapper style={{"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--26" content={"<font color=\"#c81414\">Kompenzátory vztlaku (křídla a jackety)</font>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/13078/7b1b71dff2204d65b7eea2d216d88aac_s=350x_.jpg"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7b1b71dff2204d65b7eea2d216d88aac_s=350x_.jpg 350w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":8,"paddingBottom":0}} name={"s6xzgrix9d"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654}} content={"Ceník servisu:"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":5.9999847412109375,"marginBottom":0,"paddingBottom":92.375}} name={"og43a3xcczg"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Kontrola a čištění inflátoru<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"350,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Čištění vypouštěcích ventilů</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"250,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výplach duše desinfekčním roztokem</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"320,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" style={{"maxWidth":351}} content={"<font color=\"rgba(0,0,0,1)\">Případná výměna vadných dílů (cena nezahrnuje cenu náhradních dílů)</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"dohodou"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":12.791671752929688}} name={"lahve"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":866,"paddingTop":23}} columns={"1"}>
            
            <ColumnWrapper style={{"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--26" content={"<font color=\"#c81414\">Lahve a ventily<br></font>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/13078/f60122f39ccb4e119f938ddba56093e6_s=350x_.jpg"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/f60122f39ccb4e119f938ddba56093e6_s=350x_.jpg 350w"} lightbox={true}>
              </Image>

              <Text className="text-box ff--3" style={{"marginTop":16.224990844726562,"paddingTop":0}} content={"V polovině testového období je doporučena prohlídka lahve.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":8,"paddingBottom":0}} name={"s6xzgrix9d"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654}} content={"Ceník servisu:"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":5.9999847412109375,"marginBottom":0,"paddingBottom":78.72500610351562}} name={"og43a3xcczg"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\"><b>Čištění + test monolahev<br>+ Oxy clean<br></b></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"950,-<br>+300,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Čištění + test dvojče</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1900,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Případná výměna vadných dílů (cena nezahrnuje cenu náhradních dílů)</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"dohodou"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Servis monoventilu\n<br>+ Oxy clean</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"300,-<br>+150,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Servis dvojventilu\n<br>+ Oxy clean</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"450,-<br>+200,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":12.791671752929688}} name={"pociatce"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":866,"paddingTop":23}} columns={"1"}>
            
            <ColumnWrapper style={{"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--26" content={"<font color=\"#c81414\">Potápěčské počítače<br></font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":1,"paddingTop":9,"paddingBottom":9}} name={"3o0ew4gj4a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"Baterie měníme do 3 pracovních dní."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":39.800003051757805}} name={"og43a3xcczg"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna poškozeného řemínku (bez ceny řemínku)<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"150,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna baterie a tlaková zkouška<br>- Suunto Gekko<br>- Vyper<br>- Vytec <br>- Mosquito<br>- Zoop</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"450,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna baterie a tlaková zkouška\n<br>- Suunto Stinger\n<br>- D4\n<br>- D6\n<br>- D9&nbsp;<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"990,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">- Uwatec Aladin PRO</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"1190,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box ff--3" style={{"paddingTop":29}} content={"Uvedené ceny jsou včetně baterie daného typu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":37,"marginBottom":0,"paddingBottom":12.791671752929688}} name={"suchace"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":866,"paddingTop":23}} columns={"1"}>
            
            <ColumnWrapper style={{"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--26" content={"<font color=\"#c81414\">Suché obleky<br></font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":18.991668701171875,"paddingTop":5.9999847412109375,"paddingBottom":13.734375}} name={"og43a3xcczg"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna zápěstních manžet - latex / neopren<br></font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2490,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna krční manžety - latex / neopren</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2490,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Zúžení neoprenové krční manžety</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"390,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna bot nebo ponožek URSUIT</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"4990,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna bot BARE </font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"4990,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna ponožek BARE</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"2490,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna lícnice</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"990,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna haubny </font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3490,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Montáž ventilku na haubnu</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"890,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Montáž P-valve SI TECH TRIGON, vyvážený</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3490,-"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Montáž P-valve s dodáním vašeho vlastního P-valve</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"790,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna suchého zipu (kovový BDM, plastový YKK)</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"6990,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Výměna jezdce a zámku krycího zipu</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"490,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Montáž kapsy (Tech Pocket URSUIT nebo BARE)</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"3490,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Montáž (výměna) podložky pod ventil</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"690,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Záplata bez testování obleku</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"490,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Testování těsnosti obleku</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"490,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Instalace suchých rukavic Check-Up</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"290,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box ff--3" content={"<font color=\"rgba(0,0,0,1)\">Servis obleku, 1 hodina práce technika</font>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"600,-<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box ff--3" style={{"paddingTop":29}} content={"Ceny zahrnují materiál i práci.<br>V ceně není doprava.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0}} name={"op158ycjng"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3" style={{"maxWidth":654}} content={"<a href=\"#zacatek\">zpět</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"jjl2tr617d"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":29.25}} name={"6or3qtos3e"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":452}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/7c6935e3bdc14b0ca268db5a4776c778_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/7c6935e3bdc14b0ca268db5a4776c778_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7c6935e3bdc14b0ca268db5a4776c778_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7c6935e3bdc14b0ca268db5a4776c778_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/7c6935e3bdc14b0ca268db5a4776c778_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/7c6935e3bdc14b0ca268db5a4776c778_s=2000x_.jpg 2000w"} lightbox={true} position={{"x":"-42.06008583690985%","y":"-3.8626609442060076%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/f987f620541e437b8720976df598592f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/13078/f987f620541e437b8720976df598592f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/f987f620541e437b8720976df598592f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/f987f620541e437b8720976df598592f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/f987f620541e437b8720976df598592f_s=1400x_.jpg 1400w"} lightbox={true} position={{"x":"-14.103004291845501%","y":"-5.575107296137338%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}